import { axiosCreate } from '@/services/Api'
import store from '@/state/store'

export default async function MakeVoucherReservation({
  voucher,
  items,
  contactId
}) {
  const result = await axiosCreate.post(
    `shop/reservationOrders/voucher/${encodeURIComponent(voucher)}/items`,
    items,
    {
      headers: { authorization: store.getters.token },
      params: { contactID: contactId }
    }
  )

  return result.data
}
