import { axiosCreate } from '@/services/Api'
import store from '@/state/store'

export default async function GetVoucherItems({ voucher }) {
  const result = await axiosCreate.get(
    `shop/reservationOrders/voucher/${encodeURIComponent(voucher)}/items`,
    {
      headers: { authorization: store.getters.token }
    }
  )

  return result.data
}
